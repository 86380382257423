var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headPage'),_c('div',{staticClass:"box1",staticStyle:{"position":"reletive"}},[_c('img',{staticStyle:{"display":"block","width":"100%"},attrs:{"src":require("../assets/image/niceScene/image1.png")}}),_c('div',[_vm._m(0),_c('div',{staticClass:"button"},[_c('div',{staticClass:"konw",on:{"click":function($event){return _vm.jump('/intelligentLighting')}}},[_vm._v(" 了解更多 ")])]),_vm._m(1)])]),_c('div',{staticStyle:{"position":"reletive"}},[_c('img',{staticStyle:{"display":"block","width":"100%"},attrs:{"src":require("../assets/image/niceScene/image2.png")}}),_c('div',[_vm._m(2),_c('div',{staticClass:"button2",attrs:{"data-aos":"fade-right"}},[_c('div',{staticClass:"know",on:{"click":function($event){return _vm.jump('entertainment/')}}},[_vm._v(" 了解更多 ")])]),_vm._m(3)])]),_vm._m(4),_vm._m(5),_vm._m(6),_c('footPage')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topInfo"},[_c('div',{staticClass:"title"},[_vm._v("智能照明")]),_c('div',{staticClass:"title2"},[_vm._v("从不止于灯光")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brightSpot"},[_c('img',{staticClass:"icon1",attrs:{"src":require("../assets/icon/image4.png"),"alt":""}}),_c('div',{staticClass:"points"},[_c('div',{staticClass:"p1"},[_vm._v("更专业")]),_c('div',{staticClass:"p4"},[_vm._v("人性化灯光设计")])]),_c('img',{staticClass:"icon2",attrs:{"src":require("../assets/icon/image5.png"),"alt":""}}),_c('div',{staticClass:"p2"},[_vm._v("更自然")]),_c('div',{staticClass:"p5"},[_vm._v("光感变化柔和丝滑")]),_c('img',{staticClass:"icon3",attrs:{"src":require("../assets/icon/image6.png"),"alt":""}}),_c('div',{staticClass:"p3"},[_vm._v("更智能")]),_c('div',{staticClass:"p6"},[_vm._v("场景一键切换")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info2",attrs:{"data-aos":"fade-right"}},[_c('div',{staticClass:"title3"},[_vm._v("院线级")]),_c('div',{staticClass:"title4"},[_vm._v("私人影院")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column1"},[_c('div',{staticClass:"c1"},[_vm._v("个性化"),_c('br'),_vm._v("美学装饰")]),_c('div',{staticClass:"c3"},[_vm._v("专业"),_c('br'),_vm._v("光学处理")]),_c('div',{staticClass:"c5"},[_vm._v("隔音吸音"),_c('br'),_vm._v("处理")]),_c('div',{staticClass:"c2"},[_vm._v("全景声"),_c('br'),_vm._v("音效")]),_c('div',{staticClass:"c4"},[_vm._v("4k"),_c('br'),_vm._v("高清画质")]),_c('div',{staticClass:"c6"},[_vm._v("极致"),_c('br'),_vm._v("观影体验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"reletive"}},[_c('img',{staticStyle:{"display":"block","width":"100%"},attrs:{"src":require("../assets/image/niceScene/image3.png")}}),_c('div',[_c('div',{staticClass:"info3",attrs:{"data-aos":"fade-left"}},[_c('div',{staticClass:"title5"},[_vm._v("妙用窗帘")]),_c('div',{staticClass:"title6"},[_vm._v("升华空间情绪")]),_c('div',{staticClass:"title7"},[_vm._v("当光线透进不同类型的窗帘（开合帘，卷帘，罗马帘，百叶帘），光影会发生变化使得空间层次变得丰富。让窗帘最大程度利用自然光线，升华空间情绪，赋予空间魂。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anfang"},[_c('img',{staticStyle:{"display":"block","width":"100%"},attrs:{"src":require("../assets/image/niceScene/image13.png")}}),_c('div',[_c('div',{staticClass:"info4",attrs:{"data-aos":"fade-right"}},[_c('div',{staticClass:"title8"},[_vm._v("智能安防")]),_c('div',{staticClass:"title9"},[_vm._v("24小时看家护院")]),_c('div',{staticClass:"title10"},[_vm._v("别墅四周合适位置安装高清数字摄像机，业主可通过手机远程查看每个视频图像，给客户24小时的安心，为美好生活保驾护航。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg4",staticStyle:{"position":"reletive"}},[_c('img',{staticStyle:{"display":"block","width":"100%"},attrs:{"src":require("../assets/image/niceScene/image14.png")}}),_c('div',[_c('div',{staticClass:"info5",attrs:{"data-aos":"fade-left"}},[_c('div',{staticClass:"title11"},[_vm._v("背景音乐")]),_c('div',{staticClass:"title12"},[_vm._v("唤醒生活仪式感")]),_c('div',{staticClass:"title13"},[_vm._v("伴随着背景音乐系统播放舒缓的起床曲，唤醒身体的每一个细胞，用仪式感开启美好一天。")])])])])
}]

export { render, staticRenderFns }