<template>
  <div>
    <headPage />
    <!-- 智能照明 -->
    <div  class="box1" style="position: reletive">
        <img
        style="display: block; width: 100%"
        src="../assets/image/niceScene/image1.png"
      />
      <div>
      <div class="topInfo">
        <div class="title">智能照明</div>
        <div class="title2">从不止于灯光</div>
      </div>
       <div class="button">
        <div
        class="konw"
         
        @click="jump('/intelligentLighting')">
          了解更多
        </div>
      </div>
      <div class="brightSpot">
        
        <img class="icon1"  src="../assets/icon/image4.png" alt="">
        <div class="points">
            <div class="p1">更专业</div>
            <div class="p4">人性化灯光设计</div>
        </div>
         <img class="icon2" src="../assets/icon/image5.png" alt="">
            <div class="p2">更自然</div>
            <div class="p5">光感变化柔和丝滑</div> 
         <img class="icon3" src="../assets/icon/image6.png" alt="">
        
            <div class="p3">更智能</div>
            <div class="p6">场景一键切换</div>
      </div>
      </div>
    </div>
  
    <!-- 私人影院 -->
    <div  style="position: reletive">
    <img
        style="display: block; width: 100%"
        src="../assets/image/niceScene/image2.png"
      />
      <div>
            <div class="info2"  data-aos="fade-right">
        <div class="title3">院线级</div>
        <div class="title4" >私人影院</div>
      </div>
       <div class="button2" data-aos="fade-right">
        <div 
         class="know"
        @click="jump('entertainment/')">
          了解更多
        </div>
      </div>
      <div class="column1" >
        <div class="c1">个性化<br>美学装饰</div>
        <div class="c3">专业<br>光学处理</div>
        <div class="c5">隔音吸音<br>处理</div>
        <div class="c2">全景声<br>音效</div>
         <div class="c4">4k<br>高清画质</div>
         <div class="c6">极致<br>观影体验</div>
      </div>
      </div>
    </div>
    <!-- 妙用窗帘 -->
    <div style="position: reletive">
    <img
        style="display: block; width: 100%"
        src="../assets/image/niceScene/image3.png"
      />
      <div>
      <div class="info3" data-aos="fade-left">
        <div class="title5">妙用窗帘</div>
        <div class="title6">升华空间情绪</div>
        <div class="title7">当光线透进不同类型的窗帘（开合帘，卷帘，罗马帘，百叶帘），光影会发生变化使得空间层次变得丰富。让窗帘最大程度利用自然光线，升华空间情绪，赋予空间魂。</div>
      </div>
      </div>
    </div>
    <!-- 智能安防 -->
    <div 
    class="anfang"
   >
    <img
        style="display: block; width: 100%"
        src="../assets/image/niceScene/image13.png"
      />
      <div>
       <div class="info4" data-aos="fade-right">
        <div class="title8">智能安防</div>
        <div class="title9">24小时看家护院</div>
        <div class="title10">别墅四周合适位置安装高清数字摄像机，业主可通过手机远程查看每个视频图像，给客户24小时的安心，为美好生活保驾护航。</div>
      </div>
      </div>
    </div>
     <!-- 背景音乐 -->
     <div 
     class="bg4" style="position: reletive">
    <img
        style="display: block; width: 100%;"
        src="../assets/image/niceScene/image14.png"
      /> 
      <div>
      <div class="info5" data-aos="fade-left">
        <div class="title11">背景音乐</div>
        <div class="title12">唤醒生活仪式感</div>
        <div class="title13">伴随着背景音乐系统播放舒缓的起床曲，唤醒身体的每一个细胞，用仪式感开启美好一天。</div>
      </div>
      </div>
    </div>
    <footPage />
  </div>
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.box1 {
  height: 940px;
  overflow: hidden;
  img {
    transform: translateY(-6px);
  }
}
.anfang {
  position: reletive;
  height: 414px;
}
.bg4 {
  height: 758px;
}
.points {
  position: absolute;
  left: 32px;
  top: 63px;
}
.know {
  color: black;
  font-family: SourceHanSansCN-Medium;
  cursor: pointer;
}
.topInfo {
  text-align: left;
  left: 132px;
  top: 356px;
  z-index: 999;
  position: absolute;
}
.title {
  font-size: 63px;
  color: #d0ac32;
  font-family: "SourceHanSansCN-Bold";
}
.title2 {
  font-family: "SourceHanSansCN-Light";
  font-size: 63px;
  color: #fff;
  margin-top: -10px;
}
.button {
  position: absolute;
  width: 95px;
  height: 30px;
  border: 1px solid #d0ac32;
  border-radius: 20px;
  top: 556px;
  left: 136px;
  background-color: #d0ac32;
  margin-top: 4px;
  color: black;
  font-family: "SourceHanSansCN-Medium";
  cursor: pointer;
  font-size: 16px;
  .konw {
    line-height: 30px;
  }
}
.brightSpot {
  position: absolute;
  top: 668px;
  left: 93px;

  .icon1 {
    width: 59px;
    position: absolute;
    left: 34px;
  }
  .icon2 {
    width: 47px;
    position: absolute;
    top: 4px;
    left: 303px;
  }
  .icon3 {
    width: 56px;
    position: absolute;
    top: 6px;
    left: 577px;
  }
  .p1 {
    font-size: 27px;
    color: white;
    font-family: "SourceHanSansCN-Bold";
    width: 100px;
    left: 7px;
  }
}
.p2 {
  color: white;
  position: absolute;
  left: 303px;
  width: 81px;
  font-size: 27px;
  font-family: "SourceHanSansCN-Bold";
  top: 61px;
}
.p3 {
  color: white;
  position: absolute;
  left: 590px;
  width: 84px;
  font-size: 28px;
  font-family: "SourceHanSansCN-Bold";
  top: 61px;
}
.p4 {
  width: 199px;
  position: absolute;
  color: white;
  font-size: 28px;
  left: 8px;
  top: 37px;
  font-family: "SourceHanSansCN-Light";
}
.p5 {
  position: absolute;
  left: 302px;
  width: 224px;
  font-size: 28px;
  color: white;
  top: 99px;
  font-family: "SourceHanSansCN-Light";
}
.p6 {
  position: absolute;
  left: 561px;
  width: 224px;
  font-size: 28px;
  color: white;
  top: 99px;
  font-family: "SourceHanSansCN-Light";
}
.info2 {
  text-align: left;
  left: 135px;
  top: 1124px;
  z-index: 999;
  position: absolute;
}
.info3 {
  text-align: right;
  right: 388px;
  top: 1838px;
  z-index: 999;
  position: absolute;
}
.info4 {
  text-align: left;
  left: 161px;
  top: 2506px;
  z-index: 999;
  position: absolute;
}
.info5 {
  text-align: right;
  right: 389px;
  top: 3096px;
  z-index: 999;
  position: absolute;
}
.title3 {
  font-size: 58px;
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
}
.title4 {
  font-family: "SourceHanSansCN-Blod";
  font-size: 58px;
  color: #d0ac32;
  margin-top: -10px;
  top: 87px;
  position: absolute;
  width: 252px;
  left: 2px;
}
.title5 {
  font-size: 58px;
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
}
.title8 {
  font-size: 58px;
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
}
.title11 {
  font-size: 58px;
  color: #fff;
  font-family: "SourceHanSansCN-Medium";
}
.title6 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 58px;
  color: #d0ac32;
  margin-top: -9px;
  top: 84px;
  position: absolute;
  width: 354px;
  left: -8px;
}
.title9 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 58px;
  color: #d0ac32;
  margin-top: -9px;
  top: 84px;
  position: absolute;
  width: 423px;
  left: -3px;
}
.title12 {
  font-family: "SourceHanSansCN-Medium";
  font-size: 58px;
  color: #d0ac32;
  margin-top: -9px;
  top: 84px;
  position: absolute;
  width: 423px;
  left: -17px;
}
.title7 {
  font-family: "SourceHanSansCN-Light";
  color: #d1cdca;
  top: 191px;
  position: absolute;
  width: 365px;
  left: 1px;
  justify-content: space-between;
  text-align: justify;
  font-size: 16px;
}
.title10 {
  font-family: "SourceHanSansCN-Light";
  color: #d1cdca;
  top: 191px;
  position: absolute;
  width: 370px;
  font-size: 16px;
}
.title13 {
  font-family: "SourceHanSansCN-Light";
  color: #d1cdca;
  top: 191px;
  position: absolute;
  width: 370px;
  text-align: left;
  font-size: 16px;
}
.button2 {
  position: absolute;
  width: 95px;
  height: 30px;
  border: 1px solid #d0ac32;
  border-radius: 20px;
  top: 1307px;
  left: 136px;
  background-color: #d0ac32;
  margin-top: 4px;
  color: black;
  font-family: "SourceHanSansCN-Medium";
  cursor: pointer;
  font-size: 16px;
  .know {
    line-height: 30px;
  }
}
.c1 {
  top: 1382px;
  width: 143px;
  height: 78px;
  border-radius: 7px;
  position: absolute;
  left: 137px;
  background-color: #1e1e1e;
  padding: 10px 0;
  box-sizing: border-box;
}
.c3 {
  top: 1382px;
  width: 143px;
  height: 78px;
  border-radius: 7px;
  position: absolute;
  left: 289px;
  background-color: #1e1e1e;
  padding: 10px 0;
  box-sizing: border-box;
}
.c5 {
  top: 1382px;
  width: 143px;
  height: 78px;
  border-radius: 7px;
  position: absolute;
  left: 439px;
  background-color: #1e1e1e;
  padding: 10px 0;
  box-sizing: border-box;
}
.c2 {
  top: 1465px;
  width: 143px;
  height: 78px;
  border-radius: 7px;
  position: absolute;
  left: 137px;
  background-color: #1e1e1e;
  padding: 10px 0;
  box-sizing: border-box;
}
.c4 {
  top: 1465px;
  width: 143px;
  height: 78px;
  border-radius: 7px;
  position: absolute;
  left: 289px;
  background-color: #1e1e1e;
  padding: 10px 0;
  box-sizing: border-box;
}
.c6 {
  top: 1465px;
  width: 143px;
  height: 78px;
  border-radius: 7px;
  position: absolute;
  left: 440px;
  background-color: #1e1e1e;
  padding: 10px 0;
  box-sizing: border-box;
}
.column1 {
  color: #d4d4d4;
  font-family: "SourceHanSansCN-Light";
  font-size: 20px;
}

@media (max-width: 500px) {
  .info2 {
    margin-top: 20px;
  }
  .p2 {
    width: 100px;
  }
  .title6 {
    width: 400px;
    left: -42px;
  }
  .konw {
    margin-top: 5px;
    color: black;
    font-family: SourceHanSansCN-Medium;
    cursor: pointer;
  }
}
</style>